body {
  margin: 0;
  display: flex;
  place-items: flex-start !important;
  width: 100% !important;
  min-height: 100% !important ;
}
button {
  padding: 0px;
}
